import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Table, Pagination, Button, Tooltip } from 'antd';
import ReactDragListView from 'react-drag-listview';
import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { requestSupportAsync } from 'src/app/api/support';
import { appMessage } from 'src/app/components/message';
import { typeMessage } from 'src/app/components/message/constants';
import { selectNames } from 'src/app/slices/screeningSlice';
import { ReactComponent as QualityIcon } from 'src/assets/svg/quality.svg';
import { ReactComponent as QuestionCircleIcon } from 'src/assets/svg/question.svg';
import { ReactComponent as DiagramIcon } from 'src/assets/svg/table/diagram.svg';
import { ReactComponent as AddFilter } from 'src/assets/svg/table/filter-add.svg';
import { ReactComponent as SvgArrow } from 'src/assets/svg/table/scroll-arrow.svg';

import styles from './AppDificultTable.module.css';
import { AppTableCell } from './components/cell/index';
import { ContactSupport } from './components/contact-support-modal';
import { FiltersModal } from './components/filters-modal';
import { SearchIndustry } from './components/search-modal/search-industry-modal';
import { TableSortOrder } from './components/sort-order/index';
import { AppTableSort } from './components/sort/index';

import './styles.css';

export const FilterTypes = {
  singleText: 'singleText',
  multipleText: 'multipleText',
  singleNumber: 'singleNumber',
  multipleNumber: 'multipleNumber'
};
/* @params
  columns = [
    {
      title: "Цена",
      key: 'cost',
      dataIndex: 'cost',
      filterType: filterTypes.singleText,
      sorter?: true,
      filtered?: true,
      sortTitle?: 'costValue',
      withChart?: true,
      filterTitle?: ['costValue'],
      chartData?: {},
      sortValue?: null,
      editableCellComponent?: ReactComponent,
      onChangeCell?: ReactComponent
    }
  ]

  sort: [{column: 'createdDate', direction: 'asc' | 'desc'}]
  filter: {cost: 1000}
*/
/**
 * @param {object} props
 * @param {ScreenTypes.TableColumn[]} props.columns
 *  * @param {ScreenTypes.TableColumn[]} props.data
 * @param {object[]} props.listOfData
 * @param {boolean} props.scroll
 * @param {ColumnTypes.ColumnSort[]} props.sort
 * @param {any} [props.padding]
 * @param {(e: unknown) => void} [props.onChange]
 * @param {ColumnTypes.Pagination} props.pagination
 * @param {(record: object) => void} [props.onSelectRow]
 * @param {({direction, column, sortTitle}) => void} props.onSort
 * @param {string} props.dataKey
 * @param {() => void} props.onSortOrderEnd
 * @param {() => void} props.onDeleteSort
 * @param {(page: number, pageSize: number) => void} props.onChangePagination
 *  * @param {() => void} props.onChangeCustomColumns
 * @param {boolean} [props.isLoading]
 */
export const AppDificultTable = ({
  data,
  columns,
  listOfData,
  sort,
  scroll,
  padding,
  onChange,
  pagination,
  onSelectRow,
  onSort,
  dataKey,
  onSortOrderEnd,
  onDeleteSort,
  onChangePagination,
  onChangeCustomColumns,
  isLoading
}) => {
  /** @type {UseHelper.UseStateType<ScreenTypes.TableColumnInternal[]>} */
  const [tableCols, setTableCols] = useState([]);
  const [dragCols, setDragCols] = useState([]);
  /** @type {UseHelper.UseRefType<HTMLElement>} */
  const scrollbarsRef = useRef();
  const scrollHeaderRef = useRef();
  const [isVisibleScroll] = useState(false);
  const [isSupportVisible, setIsSupportVisible] = useState(false);
  const [handleCol, setHandleCol] = useState();
  const handleColRef = useRef();
  handleColRef.current = handleCol;
  const [visible, setVisible] = useState(false);
  const [openSearchIndustryModal, setSearchIndustryModal] = useState(false);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [modalWidth, setModalWidth] = useState(600);
  const { t } = useTranslation();
  /** @type {UseHelper.UseStateType<ScreenTypes.SupportData>} */
  const [supportData, setSupportData] = useState({
    cik: null,
    tiker: null,
    data: null
  });

  const names = useSelector(selectNames);

  const dispatch = useDispatch();

  const scrollbarValueRef = useRef(scrollbarsRef?.current?.scrollLeft | 0);

  useEffect(() => {
    if (columns) {
      columns.forEach((el) => {
        if (el.columnTitle === 'Industry') {
          setSelectedIndustries(el.selectedFilter?.value);
        }
      });
    }
  }, [columns]);

  useEffect(() => {
    if (scrollbarValueRef?.current) {
      scrollbarsRef.current.scrollLeft = scrollbarValueRef.current;
    }
  });

  useEffect(() => {
    const th = document.getElementsByTagName('th');
    const scroll = document.getElementsByClassName('ps__rail-x')[2];
    // @ts-ignore
    if (scrollHeaderRef.current > 0) {
      for (let i = 0; i < th.length; i++) {
        th[i].style.top = `${scrollHeaderRef.current}px`;
      }
      // @ts-ignore
      scroll.style.top = `${scrollHeaderRef.current + 99}px`;
      // @ts-ignore
    } else if (scrollHeaderRef.current < 50) {
      for (let i = 0; i < th.length; i++) {
        th[i].style.top = '0px';
      }
      scroll.style.top = '99px';
    }
  });

  const handleScroll = () => {
    scrollbarValueRef.current = scrollbarsRef.current.scrollLeft;
  };

  const handleClickScroll = (direction) => {
    if (direction === 'right') {
      scrollbarsRef.current.scrollLeft += 20;
    } else {
      scrollbarsRef.current.scrollLeft -= 20;
    }
  };

  const changeScroll = useCallback(
    (e) => {
      let difference;
      if (sort.length) {
        difference = 150;
      } else {
        difference = 110;
      }
      const top = e.target.documentElement.scrollTop - difference;
      // @ts-ignore
      scrollHeaderRef.current = top;
      const th = document.getElementsByTagName('th');
      const scroll = document.getElementsByClassName('ps__rail-x')[2];
      if (top > 0) {
        for (let i = 0; i < th.length; i++) {
          th[i].style.top = `${top}px`;
        }
        // @ts-ignore
        scroll.style.top = `${top + 99}px`;
      } else if (top < 50) {
        for (let i = 0; i < th.length; i++) {
          th[i].style.top = '0px';
        }
        // @ts-ignore
        scroll.style.top = '99px';
      }
    },
    [sort]
  );

  useEffect(() => {
    if (scroll) {
      window.addEventListener('scroll', changeScroll);
      return () => window.removeEventListener('scroll', changeScroll);
    }
  }, [changeScroll, scroll]);

  const components = {
    body: {
      cell: AppTableCell
    },
    table: (props) => (
      <PerfectScrollbar
        onScroll={handleScroll}
        containerRef={(el) => (scrollbarsRef.current = el)}
        options={{ minScrollbarLength: 200, maxScrollbarLength: 450 }}
        className={'scroll-container'}
      >
        <table className={props.className}>{props.children}</table>
      </PerfectScrollbar>
    )
  };

  const sortTable = useCallback(
    (key, sortTitle) => {
      const sortElem = sort.find((srt) => srt.column === key);
      onSort({
        direction: sortElem && sortElem.direction === 'ASC' ? 'DESC' : 'ASC',
        column: key,
        sortTitle: t(sortTitle)
      });
    },
    [onSort, sort, t]
  );

  const checkSortActive = useCallback(
    (col) => {
      return col.sorted && !!sort.find((srt) => srt.column === (col.sortTitle || col.key));
    },
    [sort]
  );

  /** @type {ScreenFuncs.HandleOpenSupport} */
  const handleOpenSupport = useCallback(
    (cik, ticker, dataIndex, data) => {
      setIsSupportVisible((prev) => !prev);
      if (cik) {
        const column = columns.find((col) => col.dataIndex === dataIndex);
        const c = column.columnTitle || column.title;
        setSupportData({ cik, ticker, column: c, data });
      } else {
        setSupportData({ cik: null, ticker: null, column: null, data: null });
      }
    },
    [columns]
  );

  /** @type {(val: ScreenTypes.SupportData) => void} */
  const handleSubmitSupport = (val) => {
    supportData.message = val.message;
    const msg = JSON.stringify(supportData);
    dispatch(requestSupportAsync('Problem with data', msg))
      // @ts-ignore
      .then(() => {
        appMessage(typeMessage.SUCCESS, t('support_success_message'));
        setIsSupportVisible((prev) => !prev);
      })
      .catch((e) => {
        appMessage(typeMessage.ERROR, e.message);
      });
  };

  const handleOnAddFilter = useCallback((col) => {
    setModalWidth(600);
    setHandleCol(col);
    if (!col.selectedFilter.value) {
      setSelectedIndustries([]);
    }
    if (col.type === 'Quality') {
      setModalWidth(500);
    }
    if (col.dataIndex === 'industry') {
      setSearchIndustryModal((prev) => !prev);
    } else {
      setVisible((prev) => !prev);
    }
  }, []);

  useEffect(() => {
    const cols = [];
    columns.forEach((col) => {
      if (!col.isVisible) {
        return;
      }

      const anchor = col.group ? names?.find((name) => name.name_id.toString() === col.group)?.name : '';
      cols.push({
        ...col,
        title: (
          <div className="mg-difficult-table-th">
            <span className="mg-difficult-table-th__title">
              <Tooltip overlayClassName="mg-tooltip" title={t(col.columnTitle) || t(col.title)}>
                <div
                  // @ts-ignore
                  onClick={col.sorted ? () => sortTable(col.sortTitle || col.key, col.columnTitle || col.title) : null}
                >
                  <div className="mg-difficult-table-th__title_name">
                    <span>{t(col.columnTitle) || t(col.title)}</span>
                    {col.type === 'Quality' && (
                      <div>
                        <QualityIcon />
                      </div>
                    )}
                    {col.type?.includes('diagram') && (
                      <div>
                        <DiagramIcon />
                      </div>
                    )}
                  </div>
                  {col.group && (
                    <Link
                      to={`/resources/articles/definitions#${anchor || ''}`}
                      target="_blank"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <QuestionCircleIcon />
                    </Link>
                  )}
                  {col.sorted ? (
                    <AppTableSort
                      // @ts-ignore
                      sort={sort.find((srt) => srt.column === (col.sortTitle || col.key))}
                      isActive={checkSortActive(col)}
                    />
                  ) : null}
                </div>
              </Tooltip>
              {col.filter?.length ? (
                <Button
                  onClick={() => handleOnAddFilter(col)}
                  data-test="custom_screen_page_add_filter"
                  className={`mg-btn _text ${styles.addFilterButton} ${
                    col.selectedFilter?.value !== undefined &&
                    col.selectedFilter?.value !== '' &&
                    col.selectedFilter?.value !== null &&
                    (!Array.isArray(col.selectedFilter?.value) ||
                      (col.selectedFilter?.value?.length !== 0 && col.selectedFilter?.value.some((v) => v !== null)))
                      ? styles.addedFilterButton
                      : {}
                  }`}
                >
                  <AddFilter />
                </Button>
              ) : (
                ' '
              )}
            </span>
          </div>
        ),
        filtered: true,
        sorter: false,
        onCell: (record) => ({
          record,
          title: col.columnTitle || col.title,
          // chartData: col.chartData,
          dataIndex: col.dataIndex,
          onOpenContactSupport: handleOpenSupport,
          AdditionalEventsComponent: col.additionalEventsComponent,
          AdditionalEventsFirstComponent: col.additionalEventsFirstComponent,
          onChangeAdditionalEvent: col.onChangeAdditionalEvent,
          onChangeAdditionalEventChange: col.onChangeAdditionalEventChange,
          withChart: col.withChart,
          EditableCellComponent: col.editableCellComponent,
          onChangeCell: col.onChangeCell,
          onPressEnterCell: col.onPressEnterCell,
          onBlurCell: col.onBlurCell,
          editingDefault: col.editingDefault
        })
      });
    });
    if (columns) {
      const filtredCol = columns.filter((col) => col.isVisible === true);
      setDragCols(filtredCol);
    }
    setTableCols(cols);
  }, [checkSortActive, columns, handleOpenSupport, names, sort, sortTable, handleOnAddFilter, t]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      dragCols.pop();
      const filtredCol = columns.filter((col) => col.isVisible === false);
      const newColumns = [...dragCols];
      const dragItem = newColumns[fromIndex];
      let i = 0;
      let j = 0;
      if (dragItem.type === null || dragItem.type === undefined) {
        const item = newColumns.splice(fromIndex, 1)[0];
        const oldItem = newColumns[toIndex];
        if (oldItem) {
          if (oldItem.type === null || oldItem.type === undefined || oldItem.type === 'Real') {
            newColumns.splice(toIndex, 0, item);
          } else {
            if (fromIndex > toIndex) {
              if (oldItem.groupOrder === 2) {
                // @ts-ignore
                newColumns.forEach((el, k) => {
                  if (oldItem.columnTitle === el.columnTitle) {
                    i++;
                  }
                });
                if (i === 1) {
                  newColumns.splice(toIndex, 0, item);
                } else {
                  newColumns.splice(toIndex - 1, 0, item);
                }
              } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
                // @ts-ignore
                newColumns.forEach((el, k) => {
                  if (oldItem.columnTitle === el.columnTitle) {
                    i++;
                  }
                });
                if (i === 1) {
                  newColumns.splice(toIndex, 0, item);
                } else if (i === 2) {
                  newColumns.splice(toIndex - 1, 0, item);
                } else {
                  newColumns.splice(toIndex - 2, 0, item);
                }
              }
            } else {
              if (oldItem.groupOrder === 2) {
                // @ts-ignore
                newColumns.forEach((el, k) => {
                  if (oldItem.columnTitle === el.columnTitle) {
                    i++;
                  }
                });
                if (i === 2) {
                  newColumns.splice(toIndex + 1, 0, item);
                } else {
                  newColumns.splice(toIndex + 2, 0, item);
                }
              } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
                newColumns.splice(toIndex + 1, 0, item);
              }
            }
          }
        } else {
          newColumns.splice(toIndex, 0, item);
        }
      } else {
        const item = newColumns.splice(fromIndex, 1)[0];
        const oldItem = newColumns[toIndex];
        if (fromIndex < toIndex) {
          if (item.groupOrder === 1) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex++;
            }
            if (j === 2) {
              toIndex++;
            } else if (j === 3) {
              toIndex += 2;
            }
            if (i === 1) {
              const child = newColumns.splice(fromIndex, 1)[0];
              newColumns.splice(toIndex - 1, 0, item);
              newColumns.splice(toIndex, 0, child);
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex, 1)[0];
              const secondChild = newColumns.splice(fromIndex, 1)[0];
              if (toIndex - fromIndex === 1) {
                newColumns.splice(toIndex - 1, 0, item);
                newColumns.splice(toIndex - 0, 0, child, secondChild);
              } else {
                newColumns.splice(toIndex - 2, 0, item);
                newColumns.splice(toIndex - 1, 0, child, secondChild);
              }
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
          if (item.groupOrder === 2) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex++;
            }
            if (j === 2) {
              toIndex++;
            } else if (j === 3) {
              toIndex += 2;
            }
            if (i === 1) {
              const element = newColumns.find((el) => el.columnTitle === item.columnTitle);
              if (element.groupOrder === 1) {
                const child = newColumns.splice(fromIndex - 1, 1)[0];
                newColumns.splice(toIndex - 1, 0, child);
                newColumns.splice(toIndex, 0, item);
              } else {
                const child = newColumns.splice(fromIndex, 1)[0];
                newColumns.splice(toIndex - 1, 0, item);
                newColumns.splice(toIndex, 0, child);
              }
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex, 1)[0];
              const secondChild = newColumns.splice(fromIndex - 1, 1)[0];
              newColumns.splice(toIndex - 2, 0, secondChild);
              newColumns.splice(toIndex - 1, 0, item);
              newColumns.splice(toIndex, 0, child);
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
          if (item.groupOrder === 3 || item.groupOrder === 4) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex++;
            }
            if (j === 2) {
              toIndex++;
            } else if (j === 3) {
              toIndex += 2;
            }
            if (i === 1) {
              const child = newColumns.splice(fromIndex - 1, 1)[0];
              newColumns.splice(toIndex - 1, 0, child, item);
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex - 1, 1)[0];
              const secondChild = newColumns.splice(fromIndex - 2, 1)[0];
              newColumns.splice(toIndex - 2, 0, secondChild, child, item);
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
        } else {
          if (item.groupOrder === 1) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex -= 2;
            }
            if (j === 2) {
              toIndex--;
            } else if (j === 3) {
              toIndex--;
            }
            if (i === 1) {
              const child = newColumns.splice(fromIndex, 1)[0];
              newColumns.splice(toIndex, 0, item);
              newColumns.splice(toIndex + 1, 0, child);
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex, 1)[0];
              const secondChild = newColumns.splice(fromIndex, 1)[0];
              newColumns.splice(toIndex, 0, item);
              newColumns.splice(toIndex + 1, 0, child, secondChild);
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
          if (item.groupOrder === 2) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex -= 2;
            }
            if (j === 2) {
              toIndex--;
            } else if (j === 3) {
              toIndex--;
            }
            if (i === 1) {
              const element = newColumns.find((el) => el.columnTitle === item.columnTitle);
              if (element.groupOrder === 1) {
                const child = newColumns.splice(fromIndex - 1, 1)[0];
                newColumns.splice(toIndex, 0, child);
                newColumns.splice(toIndex + 1, 0, item);
              } else {
                const child = newColumns.splice(fromIndex, 1)[0];
                newColumns.splice(toIndex, 0, item);
                newColumns.splice(toIndex + 1, 0, child);
              }
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex, 1)[0];
              const secondChild = newColumns.splice(fromIndex - 1, 1)[0];
              newColumns.splice(toIndex, 0, secondChild);
              newColumns.splice(toIndex + 1, 0, item);
              newColumns.splice(toIndex + 2, 0, child);
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
          if (item.groupOrder === 3 || item.groupOrder === 4) {
            newColumns.forEach((el) => {
              if (item.columnTitle === el.columnTitle) {
                i++;
              }
            });
            if (oldItem.groupOrder === 2) {
              newColumns.forEach((el) => {
                if (oldItem.columnTitle === el.columnTitle) {
                  j++;
                }
              });
            } else if (oldItem.groupOrder === 3 || oldItem.groupOrder === 4) {
              toIndex -= 2;
            }
            if (j === 2) {
              toIndex--;
            } else if (j === 3) {
              toIndex--;
            }
            if (i === 1) {
              const child = newColumns.splice(fromIndex - 1, 1)[0];
              newColumns.splice(toIndex, 0, child, item);
            } else if (i === 2) {
              const child = newColumns.splice(fromIndex - 1, 1)[0];
              const secondChild = newColumns.splice(fromIndex - 2, 1)[0];
              if (fromIndex - toIndex === 1) {
                newColumns.splice(toIndex - 1, 0, secondChild, child, item);
              } else {
                newColumns.splice(toIndex, 0, secondChild, child, item);
              }
            } else {
              newColumns.splice(toIndex, 0, item);
            }
          }
        }
      }
      if (filtredCol.length) {
        filtredCol.forEach((el) => {
          if (el.type === null || el.type === undefined) {
            newColumns.splice(0, 0, el);
          }
          if (el.groupOrder === 1) {
            const element = newColumns.find(
              (item) =>
                item.columnTitle === el.columnTitle &&
                (item.groupOrder === 2 || item.groupOrder === 2 || item.groupOrder === 4)
            );
            const index = newColumns.indexOf(element);
            newColumns.splice(index, 0, el);
          } else if (el.groupOrder === 2) {
            const element = newColumns.find((item) => item.columnTitle === el.columnTitle && item.groupOrder === 1);
            const index = newColumns.indexOf(element);
            newColumns.splice(index + 1, 0, el);
          } else if (el.groupOrder === 3 || el.groupOrder === 4) {
            const element = newColumns.find((item) => item.columnTitle === el.columnTitle && item.groupOrder === 1);
            const index = newColumns.indexOf(element);
            newColumns.splice(index + 2, 0, el);
          }
        });
      }
      // @ts-ignore
      onChangeCustomColumns(newColumns);
    },
    nodeSelector: 'th:not(:last-child)'
  };

  const changeFilter = useCallback(
    (col) => {
      columns.pop();
      const res = columns.map((el) => {
        if (el.key === col.selectedFilter.columnName) {
          return {
            ...el,
            selectedFilter: col.selectedFilter
          };
        } else {
          return el;
        }
      });
      // @ts-ignore
      onChangeCustomColumns(res);
    },
    [columns, onChangeCustomColumns]
  );

  const handleSearchIndustry = useCallback((filter) => {
    if (filter?.value) {
      setSelectedIndustries(filter.value);
    } else {
      setSelectedIndustries([]);
    }

    setSearchIndustryModal((prev) => !prev);
  }, []);

  const handleSelectIndustries = useCallback(
    (value) => {
      if (value) {
        setSelectedIndustries(value);
        // @ts-ignore
        columns.pop();
        const res = columns.map((el) => {
          if (el.key === 'industry') {
            return {
              ...el,
              selectedFilter: { ...el.selectedFilter, value }
            };
          } else {
            return el;
          }
        });
        // @ts-ignore
        onChangeCustomColumns(res);
      }
      setSearchIndustryModal((prev) => !prev);
    },
    [onChangeCustomColumns, columns]
  );

  return (
    <div className="mg-difficult-table__wrap">
      {sort && sort.length ? (
        <TableSortOrder
          onSortEnd={onSortOrderEnd}
          isVisibleScroll={isVisibleScroll}
          sort={sort}
          onDeleteSort={onDeleteSort}
        />
      ) : (
        ''
      )}
      {isVisibleScroll ? (
        <>
          <Button
            data-test="custom_screen_page_table_difficult_scroll_right"
            className={`mg-btn _text scroll-button _right ${!sort.length && '_without-sort'}`}
            onClick={() => handleClickScroll('right')}
          >
            <SvgArrow />
          </Button>
          <Button
            data-test="custom_screen_page_table_difficult_scroll_left"
            className={`mg-btn _text scroll-button _left ${!sort.length && '_without-sort'}`}
            onClick={() => handleClickScroll('left')}
          >
            <SvgArrow />
          </Button>{' '}
        </>
      ) : (
        ''
      )}
      {data ? (
        <ReactDragListView.DragColumn {...dragProps}>
          <Table
            style={{ padding: padding }}
            onRow={(record) => {
              return {
                onClick: () => {
                  if (onSelectRow) {
                    onSelectRow(record);
                  }
                }
              };
            }}
            scroll={{ x: 1500 }}
            loading={isLoading}
            pagination={false}
            rowKey={(record) => {
              return record[dataKey || 'id'] || Math.random().toString(16).slice(2);
            }}
            onChange={onChange}
            components={components}
            columns={tableCols}
            dataSource={listOfData}
            className={`mg-difficult-table ${isVisibleScroll ? '_scroll-visible' : ''}, mg-dificult-move`}
            tableLayout={'fixed'}
          />
        </ReactDragListView.DragColumn>
      ) : (
        <Table
          style={{ padding: padding }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (onSelectRow) {
                  onSelectRow(record);
                }
              }
            };
          }}
          scroll={{ x: 1500 }}
          loading={isLoading}
          pagination={false}
          rowKey={(record) => {
            return record[dataKey || 'id'] || Math.random().toString(16).slice(2);
          }}
          onChange={onChange}
          components={components}
          columns={tableCols}
          dataSource={listOfData}
          className={`mg-difficult-table ${isVisibleScroll ? '_scroll-visible' : ''}`}
          tableLayout={'fixed'}
        />
      )}

      {pagination.total > pagination.limit ? (
        <div className="mg-table__pagination-wrap">
          <Pagination
            pageSize={pagination.limit}
            total={pagination.total}
            current={pagination.index}
            onChange={onChangePagination}
            hideOnSinglePage={true}
          />
        </div>
      ) : (
        ''
      )}
      <ContactSupport
        visible={isSupportVisible}
        onCancel={handleOpenSupport}
        onCreate={handleSubmitSupport}
        supportData={supportData}
      />
      {handleColRef.current ? (
        <FiltersModal
          visible={visible}
          width={modalWidth}
          handleOnEditVisible={handleOnAddFilter}
          col={handleColRef.current}
          // @ts-ignore
          filter={handleColRef.current.filter}
          onChange={changeFilter}
          onOpenModal={
            // @ts-ignore
            handleColRef.current.filter[0]?.columnName === 'industry'
              ? () => {
                  // @ts-ignore
                  handleSearchIndustry(handleColRef.current.selectedFilter);
                }
              : ''
          }
        ></FiltersModal>
      ) : (
        ''
      )}

      <SearchIndustry
        visible={openSearchIndustryModal}
        onSelectValues={handleSelectIndustries}
        selectedValues={selectedIndustries}
      />
    </div>
  );
};
