import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Dropdown, Menu } from 'antd';
import { useTranslation } from 'react-i18next';

import AppChart from 'src/app/components/chart/index';

import './styles.css';

const MenuList = ({ onSelectContactSupport }) => {
  const { t } = useTranslation();
  return (
    <Menu className="mg-difficult-table__menu">
      <Menu.Item className={`mg-difficult-table__contact`} key="1" onClick={onSelectContactSupport}>
        {t('account_support_subtitle')}
      </Menu.Item>
    </Menu>
  );
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false
  },
  stacked: false,
  scales: {
    x: {
      grid: {
        color: 'transparent',
        borderColor: 'transparent',
        tickColor: 'transparent'
      },
      ticks: {
        color: 'transparent'
      }
    },
    y: {
      ticks: {
        color: 'transparent',
        align: 'center'
      },
      grid: {
        tickColor: 'transparent',
        borderColor: 'transparent',
        color: 'transparent'
      }
    }
  }
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} props.withChart
 * @param {object[]} props.children
 * @param {string} props.dataIndex
 * @param {ScreenTypes.CellRecord} props.record
 * @param {()=>void} props.handleSave
 * @param {any} props.AdditionalEventsComponent
 * @param {any} props.AdditionalEventsFirstComponent
 * @param {(type: string, dataIndex: string, record_id: string)=>void} props.onChangeAdditionalEvent
 * @param {(type: string, dataIndex: string, record_id: string)=>void} props.onChangeAdditionalEventChange
 * @param {ScreenFuncs.HandleOpenSupport} props.onOpenContactSupport
 * @param {any} props.EditableCellComponent
 * @param {(val: string, dataindex: string, record: ScreenTypes.CellRecord)=>void} props.onChangeCell
 * @param {(val: string, dataIndex: string, record_id: string)=>void} props.onPressEnterCell
 * @param {(val: string, dataIndex: string, record_id: string)=>void} props.onBlurCell
 * @param {boolean} props.editingDefault
 * @returns {JSX.Element}
 */
export const AppTableCell = ({
  title,
  withChart,
  children,
  dataIndex,
  record,
  handleSave,
  AdditionalEventsComponent,
  AdditionalEventsFirstComponent,
  onChangeAdditionalEvent,
  onChangeAdditionalEventChange,
  onOpenContactSupport,
  EditableCellComponent,
  onChangeCell,
  onPressEnterCell,
  onBlurCell,
  // chartData,
  editingDefault = false,
  ...restProps
}) => {
  /** @type {UseHelper.UseStateType<ScreenTypes.ChartDataObject>} */
  const [chartDataObject, setChartDataObject] = useState(null);
  const [editing, setEditing] = useState(editingDefault);
  const [value, setValue] = useState(EditableCellComponent ? record[dataIndex] : null);
  const handleContextMenu = (e) => {
    onOpenContactSupport(record.cik, record.ticker_id, dataIndex, record[dataIndex]);
  };
  const ref = useRef(null);
  const toggleEdit = () => {
    setEditing(true);
  };

  useEffect(() => {
    setValue(EditableCellComponent ? record[dataIndex] : null);
    const handleEscapePressed = (event) => {
      if (event.key === 'Escape' && ref.current && !editingDefault) {
        setEditing(false);
      }
    };
    document.addEventListener('keydown', handleEscapePressed, true);
    return () => {
      document.removeEventListener('keydown', handleEscapePressed, true);
    };
  }, [EditableCellComponent, dataIndex, editingDefault, record]);

  const getColor = useCallback(() => {
    const dataIndexID = dataIndex.split('_')[1];
    const realValue = record[`nums_${dataIndexID}`];

    if (realValue >= 0 && realValue !== null) {
      return '#2379DE';
    } else {
      return '#AA1A56';
    }
  }, [record, dataIndex]);

  useEffect(() => {
    setValue(EditableCellComponent ? record[dataIndex] : null);
  }, [EditableCellComponent, dataIndex, editingDefault, record]);

  useEffect(() => {
    if (withChart && record && record[dataIndex]) {
      const isArray = Array.isArray(record[dataIndex]);
      setChartDataObject({
        labels: isArray ? record[dataIndex].map((el, i) => i) : Object.keys(record[dataIndex]).map((key) => key),
        datasets: [
          {
            label: '',
            data: isArray
              ? record[dataIndex].map((el) => el)
              : Object.keys(record[dataIndex]).map((key) => record[dataIndex][key]),
            borderColor: getColor(),
            pointHoverBackgroundColor: 'transparent',
            stepped: false
          }
        ]
      });
    }
  }, [dataIndex, getColor, record, withChart]);

  /** @type {(val: string) => void} */
  const changeHandler = (val) => {
    // setValue(val);
    onChangeCell(val, dataIndex, record);
  };
  const pressEnterHandler = (val) => {
    setEditing(!editing);
    onPressEnterCell(val, dataIndex, record.id);
  };
  const blurCellHandler = (val) => {
    if (
      dataIndex === 'currencyId' ||
      dataIndex === 'transactionDate' ||
      dataIndex === 'transactionType' ||
      dataIndex === 'executionDate'
    ) {
      setEditing(!editing);
      onBlurCell(val, dataIndex, record.id);
    } else {
      setEditing(!editing);
      const currentIsNan = isNaN(parseFloat(val));
      const lastIsNan = isNaN(parseFloat(value));
      const current2 = currentIsNan ? 0 : parseFloat(val);
      const last2 = lastIsNan ? 0 : parseFloat(value);
      if (current2 !== last2) {
        onBlurCell(val, dataIndex, record?.id);
      }
    }
  };

  return EditableCellComponent && record && record[dataIndex] !== undefined ? (
    <td
      data-test={`table-editable-cell-${dataIndex}`}
      {...restProps}
      onClick={toggleEdit}
      ref={ref}
      className={`ant-table-cell ${value === true ? 'ant-table-cell__green' : ''}`}
    >
      {editing ? (
        <div className="editable-field">
          <EditableCellComponent
            {...(onChangeCell && { onChange: changeHandler })}
            {...(onPressEnterCell && { onPressEnter: pressEnterHandler })}
            {...(onBlurCell && { onBlur: blurCellHandler })}
            value={value}
          />
        </div>
      ) : (
        record[dataIndex]
      )}
    </td>
  ) : withChart ? (
    <td {...restProps}>
      <div className="mg-table__chart-cell">
        {chartDataObject ? (
          <AppChart
            inTable={true}
            data={chartDataObject}
            isNeedXLine={false}
            options={options}
            displayLegend={false}
          ></AppChart>
        ) : null}
      </div>
    </td>
  ) : AdditionalEventsComponent && record ? (
    // @ts-ignore
    <td {...restProps} className={restProps.className + ' mg-table__additioanal-cell'}>
      <AdditionalEventsComponent onClickElement={(type) => onChangeAdditionalEvent(type, dataIndex, record.id)} />
    </td>
  ) : (
    <td {...restProps}>
      {record && record[dataIndex] ? (
        <Dropdown overlay={<MenuList onSelectContactSupport={handleContextMenu} />} trigger={['contextMenu']}>
          <div className="mg-difficult-table__context" style={{ display: 'flex' }}>
            {' '}
            {AdditionalEventsFirstComponent && record ? (
              <AdditionalEventsFirstComponent
                onClickElement={(type) => onChangeAdditionalEventChange(type, dataIndex, record.id)}
              />
            ) : (
              ''
            )}
            <div className="mg-difficult-table__text-wrap">{children}</div>
          </div>
        </Dropdown>
      ) : (
        <div className="mg-difficult-table__context" style={{ display: 'flex' }}>
          {' '}
          {AdditionalEventsFirstComponent && record ? (
            <AdditionalEventsFirstComponent
              onClickElement={(type) => onChangeAdditionalEventChange(type, dataIndex, record.id)}
            />
          ) : (
            ''
          )}
          <div className="mg-difficult-table__text-wrap">{children}</div>
        </div>
      )}
    </td>
  );
};
