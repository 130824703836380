import React, { useCallback, useEffect, useState } from 'react';

import { ClearOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import AppInput from 'src/app/components/form/input';
import { AppInputNumber } from 'src/app/components/form/input-number';
import { AppSelect } from 'src/app/components/form/select';

import { unitOptions } from './consts';
import styles from './FilterTableRow.module.css';
import { getUnitFromRangeInputNumber } from './helpers';

const filterTypes = {
  input: 'input',
  range: 'range',
  select: 'select',
  checkbox: 'checkbox',
  multiSelect: 'multiple_select'
};

export const FiltersModal = ({ visible, handleOnEditVisible, col, filter, onChange, onOpenModal, width }) => {
  const { t } = useTranslation();
  const [column, setColumn] = useState(col);
  useEffect(() => {
    if (col) {
      setColumn(col);
    }
  }, [col]);

  const applyFilters = () => {
    handleOnEditVisible(col);
    onChange(column);
  };

  const handleChange = useCallback(
    (columnName, value, unit) => {
      let updatedFilter = null;
      if (!unit && !value && !columnName) {
        if (column.dataKey === columnName || column.key === columnName) {
          updatedFilter = { ...column.selectedFilter, value, unit };
        }
      } else {
        if (!unit) {
          // @ts-ignore
          if (column.dataKey === columnName || column.key === columnName) {
            updatedFilter = { ...column.selectedFilter, value };
          }
        } else {
          // @ts-ignore
          if (column.dataKey === columnName || column.key === columnName) {
            updatedFilter = { ...column.selectedFilter, ...(value && { value }), unit };
          }
        }
      }

      const res = {
        ...column,
        selectedFilter: updatedFilter
      };
      setColumn(res);
    },
    [column]
  );

  const setCloseSettings = () => {
    handleOnEditVisible(col);
    setColumn(col);
  };

  const getFilter = useCallback(
    (filter) => {
      let updatedSelectedFilter = column.selectedFilter;
      const onRangeInput = (rangeIdx, val) => {
        // @ts-ignore
        const inputValue = isNaN(val) || val === '' ? null : parseFloat(val);
        if (!rangeIdx) {
          updatedSelectedFilter = handleChange(
            filter[0].columnName,
            updatedSelectedFilter?.value ? [inputValue, updatedSelectedFilter.value[1]] : [inputValue, null]
          );
        } else {
          updatedSelectedFilter = handleChange(
            filter[0].columnName,
            updatedSelectedFilter?.value ? [updatedSelectedFilter.value[0], inputValue] : [null, inputValue]
          );
        }
      };

      switch (filter[0]?.format) {
        case filterTypes.input:
          return (
            <AppInput
              className={styles.textInput}
              // @ts-ignore
              value={column.selectedFilter?.value || ''}
              onBlur={(val) => handleChange(filter[0].columnName, val)}
            ></AppInput>
          );
        case filterTypes.range:
          return (
            <div className={`${styles.rangeWrap} ${filter[0].error ? styles.rangeError : ''}`}>
              <div className={styles.rangeInput}>
                <span>
                  <Trans i18nKey="from_range"></Trans>
                </span>
                <div className={styles.rangeInputNumber}>
                  <AppInputNumber
                    value={column.selectedFilter?.value ? column.selectedFilter?.value[0] : ''}
                    onBlur={(val) => onRangeInput(0, val)}
                    onPressEnter={(val) => onRangeInput(0, val)}
                    min={null}
                  ></AppInputNumber>
                </div>
                <AppSelect
                  value={
                    column.selectedFilter?.unit
                      ? column.selectedFilter?.unit[0]
                      : column.selectedFilter?.value
                      ? getUnitFromRangeInputNumber(parseFloat(column.selectedFilter?.value[0]))
                      : null
                  }
                  placeholder="Unit"
                  options={unitOptions}
                  onChange={(val) => {
                    updatedSelectedFilter = handleChange(
                      filter[0].columnName,
                      undefined,
                      column.selectedFilter?.unit ? [val, column.selectedFilter.unit[1]] : [val, null]
                    );
                  }}
                />
              </div>

              <div className={styles.rangeInput}>
                <span>
                  <Trans i18nKey="to_range"></Trans>
                </span>
                <div className={styles.rangeInputNumber}>
                  <AppInputNumber
                    value={column.selectedFilter?.value ? column.selectedFilter?.value[1] : ''}
                    onBlur={(val) => onRangeInput(1, val)}
                    onPressEnter={(val) => onRangeInput(1, val)}
                    min={null}
                  ></AppInputNumber>
                </div>
                <AppSelect
                  value={
                    column.selectedFilter?.unit
                      ? column.selectedFilter?.unit[1]
                      : column.selectedFilter?.value
                      ? getUnitFromRangeInputNumber(parseFloat(column.selectedFilter?.value[1]))
                      : null
                  }
                  placeholder="Unit"
                  options={unitOptions}
                  onChange={(val) => {
                    updatedSelectedFilter = handleChange(
                      filter[0].columnName,
                      undefined,
                      column.selectedFilter?.unit ? [column.selectedFilter.unit[0], val] : [null, val]
                    );
                  }}
                />
              </div>
              {filter[0].error ? <span className={styles.filterError}>{filter[0].error}</span> : ''}
            </div>
          );
        case filterTypes.select: {
          const opt = column.selectedFilter?.options?.map((el) => ({ label: el.title, value: el.value }));
          return (
            <>
              {opt ? (
                <div className={styles.selectOpt}>
                  <AppSelect
                    options={opt}
                    value={column.selectedFilter?.value || null}
                    onChange={(val) => {
                      handleChange(filter[0].columnName, val, undefined);
                    }}
                  ></AppSelect>
                </div>
              ) : (
                ' '
              )}
            </>
          );
        }
        case filterTypes.multiSelect: {
          // @ts-ignore
          let opt;
          if (column.columnTitle === 'Industry') {
            opt = column.selectedFilter?.value ? column.selectedFilter?.value.map((el) => el).join(', ') : '';
          }
          return (
            <>
              {opt ? (
                <div onClick={onOpenModal}>
                  <AppInput className={styles.selectedIndustryLabel} value={opt}></AppInput>
                </div>
              ) : (
                ' '
              )}
            </>
          );
        }
      }
    },
    [onOpenModal, handleChange, column]
  );

  const getChooseFilterType = useCallback(
    (row, filter) => {
      const opt = Object.values(filter)?.map((filt) => ({ label: filt.name, value: filt.name }));
      return (
        <AppSelect
          options={opt}
          value={row.selectedFilter?.name || null}
          onChange={(val) => onOpenModal(val)}
        ></AppSelect>
      );
    },
    [onOpenModal]
  );
  return (
    <Modal
      title={'Filter'}
      centered
      width={width}
      visible={visible}
      className={`mg-modal`}
      getContainer={'main'}
      data-test={'custom_screen_page_search_column_modal'}
      onOk={applyFilters}
      okText="Set filter"
      okButtonProps={{
        className: `mg-btn _green ${styles.button}`,
        // @ts-ignore
        'data-test': 'custom_screen_page_search_column_modal_add'
      }}
      cancelButtonProps={{
        className: `mg-btn _green-border ${styles.button}`,
        // @ts-ignore
        'data-test': 'custom_screen_page_search_column_modal_cancel'
      }}
      cancelText="Cancel"
      onCancel={setCloseSettings}
    >
      <div className={styles.filterRow}>
        {Object.entries(filter).length && (
          // <div className={`${col?.type === 'Quality' ? styles.filterWrapQuality : styles.filterWrap}`}>
          <div className={`${styles.filterWrap}`}>
            {col.otherColumns && col.type === 'Real'}
            {col.type === 'Quality' && (
              <span className={(styles.filterTitle, styles.filterWrapGroupItem)}>{t('quality_filter_label')}</span>
            )}
            {Object.entries(filter).length > 1 && getChooseFilterType(col, filter)}
            <div className={styles.inputWrap}>{getFilter(filter)}</div>
          </div>
        )}
        <Button
          data-test="custom_screen_page_setting_columns_reset_view"
          className={`mg-btn _text ${styles.otherBtn}`}
          onClick={() => handleChange()}
        >
          <ClearOutlined style={{ fontSize: '20px' }} />
        </Button>
      </div>
    </Modal>
  );
};
